// colors
$water-green:   #09BC8A;
$dark-azure:    #508991;
$light-azure:   #74B3CE;
$dark-blue:     #172A3A;
$dark-blue-green:   #004346;
$white:         #fff;

$color-primary: $dark-blue;
$color-secondary: $dark-azure;
$color-link: $water-green;
$color-neutral: "";

.color-primary {
    color: $color-primary
}

.color-secondary {
    color: $color-secondary
}

