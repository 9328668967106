@import "../../style/variables";

.header {

    padding: 10px 25px;
    color: #fff;
    border-bottom: 1px solid #dddddd;
    box-shadow:  0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
    z-index: 100000;
    position: fixed;
    top: 0; left: 0;
    width: 100vw;
    background-color: rgba(255, 255, 255, 0.95);

    .logo a {

        display: flex;
        align-items: center;
        cursor: pointer;

        * {
            vertical-align: middle;
        }

        .logo-icon {
            margin: 0 10px;
            img {
                width: 45px; height: 45px;
            }
        }

        .logo-caption {
            margin: 0 10px;
            display: inline;

            .site-name {
                font-family: $font-family-cool;
            }

            .site-name-2 {
                font-size: 0.75em;
                font-style: italic;
            }

            p {
                padding: 0; margin: 0;
            }
        }
    }

    .logo a:hover .logo-caption p {
        text-decoration: none;
        color: $color-link;
    }

    .header-menu {
        width: fit-content;
        margin: 0 40px;
        display: inline-block;
        float: right;
    }
}

/*
@media screen and (max-width: $smartphone-max-width) {
    .header {

        padding: 10px 5px;

        .logo .logo-icon {
            margin: 0;
        }

        .header-menu {
            margin: 0 10px;
        }
    }
}*/

@media screen and (max-width: $small-tablet-max-width) {
    .header {

        padding: 10px 5px;

        .logo .logo-icon {
            margin: 0;
        }

        .header-menu {
            margin: 0 10px;
        }
    }
}
