@import '../../style/variables';

.home-page {

    /* Above the Fold */
    .atf {
        padding-top: 200px;
        padding-bottom: 150px;

        background: linear-gradient(
            rgba(255,255,255,1) 0%, rgba(225,225,225,1) 90%, 
            rgba(0, 0, 0, 0.5) 100%);

        .atf-logo {
            width: inherit;
            img {
                width: 100%;
            }
        }
    }

    /* 2 Cards */
    .projects-categories-bar {
        margin: 0;
        padding: 0;

        .project-category {
            height: 280px;
            width: 50%;
            display: inline-block;

            .smart-image-container a h2 {
                color: #fff;
            }

            .smart-image-container a:hover h2 {
                color: $color-link;
            }

            .category-name {
                height: fit-content;
                width: fit-content;
                text-align: center;
                margin: auto;
                margin-top: 120px;
                padding: 0;
                display: block;
            }

            .category-description {
                height: fit-content;
                width: fit-content;
                text-align: center;
                margin: auto;
                padding: 0;
                display: block;
            }
        }
    }


    /* About Me Short */
    .about-me-short {

        .about-me-short-picture {
            margin: 25px;
        }

        .about-me-short-description {
            margin: 25px;
            text-align: justify;

            ul {
                padding: 0 10px;
                li {
                    margin-top: 8px;
                }
            }
        }
    }

}

@media screen and (max-width: $smartphone-max-width) {
    .home-page {        
        .atf {    

            padding-top: 140px;
            padding-bottom: 50px;

            .atf-logo {
                margin-bottom: 15px;
            }

            .atf-title {
                text-align: center;
            }
        }

        .projects-categories-bar {
    
            .project-category {
                width: 100%;
            }
        }
    }
}