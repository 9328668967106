@import '../../style/variables';

.portrait-container {

    .portrait-box {
        position: relative;
        width:    100%; /* desired width */
    }

    .portrait-box:before {
        content:     "";
        display:     block;
        padding-top: 100%; /* initial ratio of 1:1*/
    }

    .portrait-content {
        position: absolute;
        top:      0;
        left:     0;
        bottom:   0;
        right:    0;

        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;

        border: 5px solid $dark-blue-green;
        border-radius: 50%;
    }
}

