@import "../../style/variables";

.footer {

    background-color: $light-azure;
    box-shadow:  0 -2px 4px 0 #aaa, 0 -3px 10px 0 #eee;

    .footer-main {
        margin: 25px 25px 40px 25px;

        li {
            color: $dark-blue-green;
        }

        h4 {
            color: $color-primary;
        }

        a {
            color: #fff;
        }
    }

    .footer-credits {
        text-align: center;
        padding: 15px 0;
        background-color: $color-primary;

        p, p a {
            font-family: $font-family-serious;
            font-style: italic;
            margin: 0 auto;
            font-size: inherit;
        }

        p {
            color: #fff;
            font-size: 0.8em;
        }
    }
}
