/* variables */
@import 'variables';

/* Color and Fonts */
@import url('https://fonts.googleapis.com/css2?family=Share+Tech+Mono&display=swap');

/* body */
body {
    margin: 0; padding: 0;
    width: 100vw;
    overflow-x: hidden;
}

/* text and headings */
* {
    font-family: $font-family-serious;
    font-size: $font-size;
    color: $color-primary;
}


h1 {
    color: $color-primary;
    font-family: $font-family-cool;
    font-size: 2.2em;
}
h2 {
    color: $dark-blue-green;
    font-family: $font-family-cool;
    font-size: 1.8em;
}
h3 {
    font-family: $font-family-cool;
    color: $color-secondary;
    font-size: 1.4em;
    font-weight: 400;
}

/* link */
a {
    font-family: $font-family-cool;
    color: $color-link;
    text-decoration: none;

}

a:hover {
    text-decoration: underline;
    /* font-size: 1.05em; */
}








