@import "../../style/colors";
@import "../../style//fonts";

.project-box-container {
    .project-intro {
        .project-img {
            margin: 5px;
            img {
                width: 100%;
            }
        }
    }
}

.project-box-preview {
    .project-intro {
        .project-img {
            width: 100%; height: 280px;
        }
    }
}

.project-external-links {
    .project-link {
        margin: 5px 12px;
    }
}

.project-tags-box {
    display: flexbox;
    vertical-align: middle;

    .project-tag {

        margin: auto 15px;

        display: flexbox;
        vertical-align: middle;

        .project-tag-icon {
            height: 25px;
            img {height: inherit;}
        }

        .project-tag-name {
            color: $light-azure;
            font-family: $font-family-cool;
        }
    }
}

.project-body {
    text-align: justify;
}