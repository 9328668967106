@import '../../style/variables';

.page-portfolio {
    .websites-grid {
        .websites-grid-cell {
            //border: 1px solid black;
            margin: 8px 0;
            width: 100%;

            .site-name {
                margin-top: 50px;                
                text-align: center;
                padding: 0 10px;
                display: block;

                h3, p {
                    color: #fff;
                }
            }
        }

        .websites-grid-cell:hover .site-name {
            h3, p {
                color: $color-primary;
            }
        }

        .websites-grid-cta {
            margin: 0;
            height: 300px;
            width: 100%;
            display: inline-block;
            text-align: center;
            background-color: $color-primary;

            .websites-grid-cta-content {
                height: fit-content;
                margin: 90px 10px;

                h3 {
                    color: $color-link;
                }

                .cta-button {
                    background-color: #eee;
                    padding: 8px 18px 10px 18px;
                    border-radius: 15px;
                    cursor: pointer;
                    font-style: italic;
                    color: $color-primary;
                }
    
                .cta-button:hover {
                    background-color: #fff;
                    color: $color-link;
                }
            }

            
        }
    }
}