.smart-image-container {
    margin: 0;
    display: flex;
    overflow: hidden;
    align-items: center;
    position: relative;

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    .smart-image-hover {
        position: absolute; top: 0;
        height: 100%; width: 100%;
        z-index: 1000;
        transition: background-color 0.5s ease;
    }

    .smart-image-hover-opac {
        background-color: rgba($color: #000, $alpha: 0.8);
    }

    a {
        width: 100%; height: 100%;
        display: block;
    }

    a:hover .smart-image-hover, a:hover .smart-image-hover-opac {
        background-color: rgba($color: #fff, $alpha: 0.7);
    }   

    .smart-image-container-content {
        width: 100%; height: 100%;
        position: relative;
        z-index: 1001;
        vertical-align: middle;
    }

}