@import '../../../style/variables';

.menu-container {

    display: inline-flexbox;

    .menu {

        vertical-align: middle;
        display: table-cell;

        nav ul {
            padding: 0;
        }

        nav ul li {
            display: inline-block;
            margin: auto 15px;

            a {
                font-family: $font-family-cool;
            }
        }
    }

    .menu-icon-mobile {
        display: none;
    }
}

@media screen and (max-width: $large-tablet-max-width) {

    .menu-container {

        .menu-icon-mobile {
            display: inline-block;
        }

        .menu {

            position: absolute;
            top: 68px; left: -100vw;
            width: 100vw;
            z-index: 500;

            nav {
                margin: 70px auto;
                text-align: center;

                ul {
                    padding: 0;
                    li {
                    margin: 15px;
                    display: block;
                    }
                }

                .sep {
                    display: none;
                }
            }
        }

        .menu-mobile-open {
            left: 0;
            background-color: #fff;
            border-bottom: 1px solid #dddddd;
            box-shadow:  0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 2px 5px 0 rgba(0, 0, 0, 0.19);
        }
    }
}
/*
@media screen and (max-width: $small-tablet-max-width) {

    .menu-container {
        height: 50px;

        .menu {
            top: 70px
        }
    }
}

@media screen and (max-width: $smartphone-max-width) {

    .menu-container {
        height: 35px;

        .menu {
            top: 50px;
        }

        .menu-icon-mobile {
            position: relative;
            bottom: 8px;
        }
    }
}*/

// Menu Voice

.menu-voice {

    padding: auto 5px;

    > span {
        margin: 5px;
        vertical-align: middle;
    }

    .menu-voice-icon {

        a {
            text-decoration: none;
        }

        img {
            height: 24px;
        }

    }

    .menu-voice-label a {
        color: $light-azure;
        font-size: 1em;
    }
}

.menu-voice-hover .menu-voice-label a {
    color: $water-green;
}
